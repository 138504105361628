import { graphql } from "gatsby";
import { createGatsbyPageComponent } from "@shapeable/ui";
import { CatalystExplorerLayout } from "../../components/entities/catalyst-explorer-layout";

export default createGatsbyPageComponent('Catalyst', { layout: CatalystExplorerLayout });

export const query = graphql`
query CatalystQuery($id: ID!) {
  platform { entityTypeDefinition(entityTypeName: "Catalyst") {
    typeSlug
  }
  catalyst(id: $id) {
    id name path slug __typename _schema { label pluralLabel }
    banner { openGraph { id image { id url url2x } } }
    openGraph { title image { url url2x thumbnails { card { url url2x } bubble { url url2x } } } }
    description { id text }
    exampleModel { id text }
    innovations {
      id name slug path __typename
    }
  } }
}
`