import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable, ContentNode as TContentNode } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { classNames } from '@shapeable/utils';
import { Catalyst } from '@shapeable/copper-connect-types';
import { ContentNode, ContentTitle, EntityMetaSection, EntityValue, useLang } from '@shapeable/ui';
import { EntityEmbedReveal } from './entity-embed-reveal';
const cls = classNames('catalyst-view-layout');

// -------- Types -------->

// -------- Props -------->

export type CatalystViewLayoutProps = Classable & HasChildren & { 
  entity?: Catalyst;
};

export const CatalystViewLayoutDefaultProps: Omit<CatalystViewLayoutProps, 'entity'> = {
};

// -------- Child Component Props -------->

type ContainerProps = {

};

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    margin-top: ${theme.UNIT(7)};
  `,
});

const SectionStyles = breakpoints({
  base: css`
    margin-bottom: ${theme.UNIT(5)};
  `,
});

const ContentTitleStyles = breakpoints({
  base: css`
    font-family: ${theme.FONT('heading')};
    font-weight: 600;
    color: ${theme.COLOR('heading')};
    font-size: 1em;
    margin-bottom: 0.5em;
  `,
});

const DescriptionStyles = breakpoints({
  base: css`
    
  `,
});

const ExampleStyles = breakpoints({
  base: css`

  `,
});

const InnovationsStyles = breakpoints({
  base: css`
    padding-bottom: ${theme.UNIT(3)};
  `,
});

// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
    Section: styled.section`${SectionStyles}`,
    ContentTitle: styled.h1`${ContentTitleStyles}`,
      Description: styled(ContentNode)`${DescriptionStyles}`,
      Example: styled(ContentNode)`${ExampleStyles}`,
      Innovations: styled(EntityMetaSection)`${InnovationsStyles}`,
};

export const CatalystViewLayout: Shapeable.FC<CatalystViewLayoutProps> = (props) => {
  const { className, children, entity } = props;

  const { description, innovations = [], exampleModel } = entity;

  const hasInnovations = !!innovations;

  const t = useLang();
  
  return (
    <My.Container className={cls.name(className)}>
      <My.Section>
        <My.Description entity={description as TContentNode} />
      </My.Section>
      <My.Section>
        <My.ContentTitle>{t('Example Model')}</My.ContentTitle>
        <My.Example entity={exampleModel as TContentNode} />
      </My.Section>
        {
          hasInnovations && (
            <My.Section>
              <My.ContentTitle>{t('Innovations')}</My.ContentTitle>
              <My.Innovations items={innovations} />
            </My.Section>
          )}
      {children}
    </My.Container>
  )
};

CatalystViewLayout.defaultProps = CatalystViewLayoutDefaultProps;
CatalystViewLayout.cls = cls;